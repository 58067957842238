export const AboutImagesList = [
  { name: "about24.jpg" },
  { name: "about17.jpg" },
  { name: "about27.jpg" },
  { name: "about20.jpg" },
  { name: "about23.jpg" },
  { name: "about19.jpg" },
  { name: "about22.jpg" },
  { name: "about18.jpg" },
  { name: "about21.jpg" },
  { name: "about26.jpg" },
  { name: "about25.jpg" },
  { name: "about1.jpg" },
  { name: "about2.jpg" },
  { name: "about3.jpg" },
  { name: "about12.jpg" },
  { name: "about13.jpg" },
  { name: "about14.jpg" },
  { name: "about4.jpg" },
  { name: "about15.jpg" },
  { name: "about5.jpg" },
  { name: "about6.jpg" },
  { name: "about16.jpg" },
  { name: "about8.jpg" },
  { name: "about9.jpg" },
  { name: "about10.jpg" },
];
