export const KarlovacAttractions = [
    {
        title: "dubovac",
        description: 'dubovac_desc',
        imageName: "dubovac",
        url: "https://visitkarlovac.hr/stari-grad-dubovac/",
        used: true
    }, {
        title: "zvijezda",
        description: 'zvijezda_desc',
        imageName: "zvijezda",
        url: "https://visitkarlovac.hr/zvijezda/",
        used: true
    }, {
        title: "grain_boat",
        description: 'grain_desc',
        imageName: "zitna",
        url: "https://visitkarlovac.hr/zitna-lada/",
        used: true
    }, {
        title: "aquatika",
        description: 'aquatika_desc',
        imageName: "aquatika",
        url: "https://visitkarlovac.hr/aquatika/",
        used: true

    }, {
        title: "four_rivers",
        description: 'four_rivers_desc',
        imageName: "rijeke",
        url: "https://visitkarlovac.hr/cetiri-rijeke/",
        used: true
    }, {
        title: "dani_piva",
        description: 'dani_piva_desc',
        imageName: "danipiva",
        url: "https://danipiva.net/",
        used: true
    }, {
        title: "homeland_war_museum",
        description: 'homeland_war_museum_desc',
        imageName: "muzejturanj",
        url: "https://visitkarlovac.hr/muzej-domovinskog-rata/",
        used: true
    }, {
        title: "city_of_parks",
        description: 'city_of_parks_desc',
        imageName: "parkovi",
        url: "https://visitkarlovac.hr/parkovi-i-perivoji/",
        used: true
    }, {
        title: "star_summer",
        description: 'star_summer_desc',
        imageName: "zvjezdanoljeto",
        url: "https://zvjezdanoljeto.karlovac.hr/",
        used: true
    }, {
        title: "nat_shr_st_joseph",
        description: 'nat_shr_st_joseph_desc',
        imageName: "nacsvetiste",
        url: "https://www.svetijosip.com/",
        used: true
    }
]